.bg-header {
    background-image: url('../../../public/assets/img/slide/career/career.jpg');
    background-size: cover;
    height: 480px;
}
.bg-header.detail-job {
    height: 440px;
}
.bg-header h4 {
    position: relative;
    top: 50%;
    text-align: center;
    font-size: 5em;
    color: #fff;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(0, 0, 0, 0.3);
}
.bg-header h4.job-title {
    font-size: 3em;
}
.content-lowongan {
    padding: 20px 0 100px;
}
.content-detail-lowongan {
    padding: 50px 0 100px;
}
.kat i {
    color: red;
}
.filter-card {
    max-width: 900px;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: auto;
    position: relative;
    top: -50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.category-card {
    max-width: 900px;
    background: #fff;
    border-radius: 8px;
    margin: auto;
    position: relative;
    padding: 30px;
    top: -50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card-lowongan {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.card-lowongan p {
    margin-bottom: 5px;
}
.button {
    text-align: right;
}
.form-card {
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.form-card h4 {
    text-align: center;
    margin-bottom: 20px;
}
.form-card .form-group {
    margin-bottom: 10px;
}

@media (width < 768px) {
    .filter-card {
        max-width: 350px;
    }
    .filter-card .form-select {
        margin-bottom: 10px;
    }
}
