.merchandise {
  padding: 0;
}
.list-merchandise {
  padding: 10%;
  margin-top: -9%;
}
.card-title {
  color: #000;
}
.bg-header {
  background-image: url("../../../public/assets/img/slide/career/career.jpg");
  background-size: cover;
  height: 480px;
}
.bg-header.detail-job {
  height: 440px;
}
.bg-header h4 {
  position: relative;
  top: 50%;
  text-align: center;
  font-size: 5em;
  color: #fff;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(0, 0, 0, 0.3);
}
.bg-header h4.job-title {
  font-size: 3em;
}
.content-merchandise {
  padding: 150px 0 100px;
}
.content-detail-lowongan {
  padding: 50px 0 100px;
}
.kat i {
  color: red;
}
.filter-card {
  max-width: 900px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  position: relative;
  top: -50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.category-card {
  max-width: 900px;
  background: #fff;
  border-radius: 8px;
  margin: auto;
  position: relative;
  padding: 30px;
  top: -50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card-merchandise {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 40px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.card-merchandise p {
  margin-bottom: 5px;
}
.button {
  text-align: right;
}
.form-card {
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.form-card h4 {
  text-align: center;
  margin-bottom: 20px;
}
.form-card .form-group {
  margin-bottom: 10px;
}

.counter {
  width: 150px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter input {
  width: 50px;
  border: 0;
  line-height: 30px;
  font-size: 20px;
  text-align: center;
  background: #c0c0c0;
  color: #fff;
  appearance: none;
  outline: 0;
}
.counter span {
  display: block;
  font-size: 25px;
  padding: 0 10px;
  cursor: pointer;
  color: #000000;
  user-select: none;
}
.cart {
  position: relative;
  display: block;
  width: 28px;
  height: 28px;
  height: auto;
  overflow: hidden;
  margin-left: auto;
  .bagg {
    position: relative;
    top: 4px;
    z-index: 1;
    font-size: 24px;
    color: #000;
  }
  .count {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    font-size: 11px;
    border-radius: 50%;
    background: #d60b28;
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    text-align: center;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }
}

.nav-merch {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}
.btn-cart {
  display: flex;
  border: 1px solid #000;
}
.btn-merc {
  display: flex;
  align-items: center;
  border: 1px solid #000;
}
.btn-cart p,
.btn-merc p {
  margin: auto;
  margin-left: 5px;
}

.box-form {
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* -------------------------------------------------- */

/* ----- Tracking CSS ------*/
.box-tracking {
  max-width: 550px;
  margin: 50px auto;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

.box i {
  font-size: 1.8em;
  color: #fff;
}

.step .box {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9a0d0d;
  z-index: 222;
}

.step .line {
  top: 40px;
  left: 19px;
  height: 100%;
  position: absolute;
  border-left: 3px solid rgb(169, 49, 49);
}

.step.completed .line {
  /* border-left: 3px solid rgb(6,150,215); */
  border-left: 3px solid rgb(205, 32, 32);
}

.step.active .box {
  visibility: visible;
  border-color: rgb(6, 150, 215);
}

.step.empty .box {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the box to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
}

.content h5 {
  font-weight: 500;
}
/* ------------------------ */

@media (width < 768px) {
  .filter-card {
    max-width: 350px;
  }
  .filter-card .form-select {
    margin-bottom: 10px;
  }
}
