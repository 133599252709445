.card-artikel {
  position: relative;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 20px 5px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* align-items: flex-start; */
}
.judulartikel {
  margin-left: 177px;
}
.card-artikel .card-img-top-artikel {
  padding-top: 15px;
  width: 90%;
  height: auto;
  margin-bottom: 20px;
  align-self: center;
}
.card-title {
  text-align: left;
  padding-left: 20px;
}
.card-body {
  display: flex;
  flex-direction: column;

  /* justify-content: start; */
  text-align: justify;
}
/* .card-artikel .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
} */

.card-artikel .card-body .btn {
  margin-top: auto;
}
.btn-primary {
  margin-left: auto;
}
.tanggal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  /* font-weight: bold; */
}
.list-group-kategori {
  background-color: rgba(255, 255, 255, 0.654);
  padding: 10px;
  border-radius: 19px;
  box-shadow: 3px 9px 9px rgba(0, 0, 0, 0.1);
}

.list-group-kategori .list-group-item {
  border: none;
  padding: 8px 12px;
}

.list-group-kategori .list-group-item:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.list-group-kategori .list-group-item.active {
  background-color: rgba(255, 0, 0, 0.2);
}

/* artikel detail */

.card-artikel-sidebar {
  /* box-shadow: rgba(208, 208, 208, 0.1) 0px 4px 16px,
    rgba(56, 56, 58, 0.05) 0px 8px 32px; */
  position: relative;
  padding: 8px 5px;
  border-radius: 10px;
  margin-bottom: -30px;
  display: flex;
  flex-direction: column;
}
.card-artikel-sidebar .card-body {
  padding: 0;
}
.img-artikel-sidebar {
  padding-right: 5%;
  padding-bottom: 2%;
}

.font-sidebar {
  font-size: 13px;
}
.title-sidebar {
  margin-bottom: 5px;
  font-size: 15px;
  color: black;
}
li {
  list-style: none;
}

.card-artikel-sidebar .card-body .card-title,
.card-artikel-sidebar .card-body .card-text {
  color: black;
  padding: 1%;
}

.judul {
  margin-top: 6.5rem;
  margin-bottom: 1rem;
}
.card .cardimg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.author {
  flex: 1; /* Elemen ini akan memanfaatkan sebanyak mungkin ruang tersedia */
  margin-right: 10px; /* Jarak kanan dari elemen lain */
}

.tanggal_detail {
  flex: 1;
  text-align: right;
}

.card-text-artikel {
  font-size: large;
  overflow: hidden;
}
.float-right {
  float: right;
}

.cardimg.float-right {
  float: right;
  margin: 0 0 1em 1em;
  max-width: 50%; /* Ensure the image does not take too much space */
  height: auto;
}

.card-text-artikel {
  overflow: hidden;
  font-size: large;
}

.cardimg {
  /* display: block; */
  margin: 0 auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%;
    padding: 0 10px;
  }

  .cardimg.float-right {
    float: none;
    margin: 0 auto;
    max-width: 100%;
  }

  .judul {
    margin-top: 6.5rem;
    margin-bottom: 1rem;
  }
}

.card-title-sidekonten {
  margin: 0;
  padding: 0;
}
.card-konten-2 {
  max-width: 800px;
  padding-bottom: 5%;
}
@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%; /* Container mengisi lebar layar */
    padding: 0 10px; /* Padding samping */
  }

  .gambar-detail-artikel {
    height: auto; /* Tinggi gambar setara dengan 50% dari tinggi viewport */
    width: auto; /* Lebar gambar disesuaikan secara otomatis sesuai dengan rasio aspek */
  }
  .judulartikel {
    margin-left: 0px;
  }
}
